import React, { Suspense, lazy, useState } from 'react';
import { auth } from '../../firebase-config';
import { Link } from 'react-router-dom';
import routes from '../../data/routes';

const Menu = lazy(() => import('react-burger-menu/lib/menus/slide'));

const Hamburger = () => {
  const [open, setOpen] = useState(false);
  const { PUBLIC_URL } = process.env;
  const [cate, setCate] = React.useState(null)

  const routes = [
    {
      index: true,
      label: 'H2L',
      path: '/',
      liste: false,
      logo: true,
      source: `${PUBLIC_URL}/images/projects/logo_menu.png`,
      sousmenu: []
    },
    {
      label: 'Achat',
      path: '/achat',
      liste: true,
      logo: false,
      source: false,
      sousmenu: cate === null ? [] : cate[0].cate
    },
    {
      label: 'Outils coupants',
      path: '/outils',
      liste: true,
      logo: false,
      source: false,
      sousmenu: cate === null ? [] : cate[6].cate
    },
    {
      label: 'Machines',
      path: '/machine',
      liste: true,
      logo: false,
      source: false,
      sousmenu: cate === null ? [] : cate[3].cate
    },
    {
      label: 'Metrologie',
      path: '/metrologie',
      liste: true,
      logo: false,
      source: false,
      sousmenu: cate === null ? [] : cate[4].cate
    },
    {
      label: 'Materiel divers',
      path: '/divers',
      liste: true,
      logo: false,
      source: false,
      sousmenu: cate === null ? [] : cate[2].cate
    },
    {
      label: 'Destockage',
      path: '/destockage',
      liste: false,
      logo: false,
      source: false,
      sousmenu: []
    },
    {
      label: 'Contact',
      path: '/contact',
      liste: false,
      logo: false,
      source: false,
      sousmenu: []
    },
  ];

  const adminroute = [
    {
      index: true,
      label: 'H2L',
      path: '/',
      liste: false,
      logo: true,
      source: `${PUBLIC_URL}/images/projects/logo_menu.png`
    },
    {
      label: 'Gestion des stocks',
      path: '/gestion',
      liste: false,
      logo: false,
      source: false
    },
    {
      label: 'Gestion de la base de donnée',
      path: '/ajout',
      liste: false,
      logo: false,
      source: false
    }
  ]

  if (auth.currentUser === null){
    return (
          <div className="hamburger-container">
            <nav className="main" id="hambuger-nav">
              <ul>
                {open ? (
                  <li className="menu close-menu">
                    <div onClick={() => setOpen(!open)} className="menu-hover">&#10005;</div>
                  </li>
                ) : (
                  <li className="menu open-menu">
                    <div onClick={() => setOpen(!open)} className="menu-hover">&#9776;</div>
                  </li>
                )}
              </ul>
            </nav>
            <Suspense fallback={<></>}>
              <Menu right isOpen={open} disableAutoFocus>
                <ul className="hamburger-ul">
                  {routes.map((l) => (
                    <li key={l.label}>
                      <Link to={l.path} onClick={() => setOpen(!open)}>
                        <h3 className={l.index && 'index-li'}>{l.label}</h3>
                      </Link>
                    </li>
                  ))}
                </ul>
              </Menu>
            </Suspense>
          </div>
        );
    }
    else {
      return (
        <div className="hamburger-container">
        <nav className="main" id="hambuger-nav">
          <ul>
            {open ? (
              <li className="menu close-menu">
                <div onClick={() => setOpen(!open)} className="menu-hover">&#10005;</div>
              </li>
            ) : (
              <li className="menu open-menu">
                <div onClick={() => setOpen(!open)} className="menu-hover">&#9776;</div>
              </li>
            )}
          </ul>
        </nav>
        <Suspense fallback={<></>}>
          <Menu right isOpen={open} disableAutoFocus>
            <ul className="hamburger-ul">
              {adminroute.map((l) => (
                <li key={l.label}>
                  <Link to={l.path} onClick={() => setOpen(!open)}>
                    <h3 className={l.index && 'index-li'}>{l.label}</h3>
                  </Link>
                </li>
              ))}
            </ul>
          </Menu>
        </Suspense>
      </div>
      )
    }
};

export default Hamburger;
