// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { ref, uploadBytes, getDownloadURL, getStorage } from 'firebase/storage';
import { collection, query, where, getDocs, doc, deleteDoc, updateDoc, arrayUnion, arrayRemove  } from "firebase/firestore";
import ToastCustom from "./components/Template/Toast";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING,
  appId: process.env.REACT_APP_APP_ID
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app)

// get by condition
export const GetProduitByOnglet = async (col, setData) => {
  const data = []
  const q = query(collection(db, "produits"), where("categorie", "==", col));
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    data.push(doc.data())
  });
  setData(data)
}

// get nouveaute
export const GetOnlyNouveate = async (setData) => {
  const data = []
  const q = query(collection(db, "produits"), where("nouveaute", "==", true));
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    data.push(doc.data())
  });
  setData(data)
}

// get full product
export const GetFullProduct = async (setData) => {
  const data = []
  const q = collection(db, "produits")
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    data.push(doc.data())
  });
  setData(data)
}

// get categorie
export const GetCategorieProduct = async (setData) => {
  const data = []
  const querySnapshot = await getDocs(collection(db, "categories"));
  querySnapshot.forEach((doc) => {
    data.push(doc.data())
  });
  const structure = []
  for (const x of Object.keys(data[0].categories)){
    if (typeof data[0].categories[x] === "string"){
      structure.push({onglet: x, cate: [data[0].categories[x]]})
    }
    else {
      structure.push({onglet: x, cate: data[0].categories[x]})
    }
  }
  structure.sort((a, b) => {
    const nameA = a.onglet.toUpperCase(); // Ignorer la casse
    const nameB = b.onglet.toUpperCase(); // Ignorer la casse
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0; // Les noms sont égaux
  });
  setData(structure)
}

export const deleteProduct = async (productId) => {
  try {
    await deleteDoc(doc(db, "produits", productId));
    ToastCustom("Produit supprimé", true)
  } catch (error) {
    ToastCustom("Erreur lors de la suppression", false)
  }
};

export const updateProduct = async (productId, updatedData) => {
  try {
    await updateDoc(doc(db, "produits", productId), updatedData);
    ToastCustom("Produit mis à jour", true)
  } catch (error) {
    ToastCustom("Erreur lors de la mise à jour", false)
  }
};

export const uploadImageToFirebaseStorage = async (file, refArticle) => {
  // Obtenez une référence à l'emplacement où vous souhaitez stocker le fichier.
  const storageRef = ref(storage, 'images/' + refArticle);

  // Téléversez le fichier vers le stockage Firebase Storage.
  try {
    const snapshot = await uploadBytes(storageRef, file);
    return await getDownloadURL(snapshot.ref);
  } catch (error) {
    // Gérez les erreurs de téléversement ici.
    console.error(error);
  }
}

// Supprime une valeur du tableau de la catégorie donnée
export const removeCategoryValue = async (category, value) => {
  try {
    const docRef = doc(db, 'categories', 'dx19gsYrzP6KPDBrAoNh');
    await updateDoc(docRef, {
      [`categories.${category}`]: arrayRemove(value)
    });
    ToastCustom("Categorie supprimée avec succes", true)
  } catch (error) {
    ToastCustom("Erreur lors de la suppression", false);
  }
}

// Ajoute une valeur au tableau de la catégorie donnée
export const addCategoryValue = async (category, value) => {
  try {
    const docRef = doc(db, 'categories', 'dx19gsYrzP6KPDBrAoNh');
    await updateDoc(docRef, {
      [`categories.${category}`]: arrayUnion(value)
    });
    ToastCustom("Categorie ajoutée avec succes", true);
  } catch (error) {
    ToastCustom("Erreur lors de l'ajout", false);
  }
}

// Met à jour une valeur existante dans le tableau de la catégorie donnée
export const updateCategoryValue = async (category, oldValue, newValue) => {
  try {
    const docRef = doc(db, 'categories', 'dx19gsYrzP6KPDBrAoNh');
    await updateDoc(docRef, {
      [`categories.${category}`]: arrayUnion(newValue),
      [`categories.${category}`]: arrayRemove(oldValue)
    });
    ToastCustom("Categorie mise à jour", true);
  } catch (error) {
    ToastCustom("Erreur lors de la mise à jour", false);
  }
}

