import * as React from "react";
import { GetCategorieProduct } from "../firebase-config";

// const [cate, setCate] = React.useState([])
// console.log(cate)

// React.useEffect(() => {
//   GetCategorieProduct(setCate)
// }, [])

const { PUBLIC_URL } = process.env;

const routes = [
  {
    index: true,
    label: 'H2L',
    path: '/',
    liste: false,
    logo: true,
    source: `${PUBLIC_URL}/images/projects/logo_menu.png`,
    sousmenu: []
  },
  {
    label: 'Achat',
    path: '/achat',
    liste: true,
    logo: false,
    source: false,
    sousmenu: ['Test', 'Option 2', 'Option 3', 'Option 4', 'Option 5']
  },
  {
    label: 'Outils coupants',
    path: '/outils',
    liste: true,
    logo: false,
    source: false,
    sousmenu: ['Option 1', 'Option 2', 'Option 3', 'Option 4', 'Option 5']
  },
  {
    label: 'Machines outils',
    path: '/machine',
    liste: true,
    logo: false,
    source: false,
    sousmenu: ['Option 1', 'Option 2', 'Option 3', 'Option 4', 'Option 5']
  },
  {
    label: 'Materiel divers',
    path: '/divers',
    liste: true,
    logo: false,
    source: false,
    sousmenu: ['Option 1', 'Option 2', 'Option 3', 'Option 4', 'Option 5']
  },
  {
    label: 'Destockage',
    path: '/destockage',
    liste: false,
    logo: false,
    source: false,
    sousmenu: []
  },
  {
    label: 'Contact',
    path: '/contact',
    liste: false,
    logo: false,
    source: false,
    sousmenu: []
  },
];

export default routes;
