import { toast } from 'react-toastify';

const ToastCustom = (Text, Success) => {

    if (Success === true) {
        toast.success(Text, {
            position: "bottom-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            style: {
                backgroundColor: '#30c44b',
                color: "white",
            },
            progressStyle: {
                background: "white",
            }})
    }
    else {
        toast.error(Text, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            style: {
                backgroundColor: '#ec2210',
                color: "white",
            },
            progressStyle: {
                background: "white",
            }
            })
    }
}

export default ToastCustom