import React, { Suspense, lazy } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import Main from './layouts/Main'; // fallback for lazy pages
import './static/css/main.scss'; // All of our styles
import { auth } from './firebase-config';

const { PUBLIC_URL } = process.env;

// Module public
const Achat = lazy(() => import('./pages/Achat'));
const Contact = lazy(() => import('./pages/Contact'));
const Index = lazy(() => import('./pages/Index'));
const NotFound = lazy(() => import('./pages/NotFound'));
const Outil = lazy(() => import('./pages/Outil'));
const Divers = lazy(() => import('./pages/Divers'));
const Machine = lazy(() => import('./pages/Machine'));
const Destockage = lazy(() => import('./pages/Destockage'));
const Connexion = lazy(() => import('./pages/Connexion'));
const Metrologie = lazy(() => import('./pages/Metrologie'));

// Module de gestion
const Gestion = lazy(() => import('./pages/Gestions'))
const Ajout = lazy(() => import('./pages/AddArticle'))


const App = () => {

    return (
      <BrowserRouter basename={PUBLIC_URL}>
        <Suspense fallback={<Main />}>
          <Switch>
            <Route exact path="/" component={Index} />
            <Route path="/achat" component={Achat} />
            <Route path="/outils" component={Outil} />
            <Route path="/machine" component={Machine} />
            <Route path="/divers" component={Divers} />
            <Route path="/metrologie" component={Metrologie} />
            <Route path="/destockage" component={Destockage} />
            <Route path="/contact" component={Contact} />
            <Route path="/connexion" component={Connexion} />
            <Route path="/gestion" component={Gestion} />
            <Route path="/ajout" component={Ajout} />
            <Route component={NotFound} status={404} />
          </Switch>
        </Suspense>
      </BrowserRouter>
    )
}

export default App;
