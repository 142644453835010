import { faEnvelope } from '@fortawesome/free-regular-svg-icons/faEnvelope';
import { faInstagram } from '@fortawesome/free-brands-svg-icons/faInstagram';

const data = [
  {
    link: 'https://www.instagram.com/h2l_industrie/',
    label: 'Instagram',
    icon: faInstagram,
  },
  {
    link: 'mailto:js2lh@orange.fr',
    label: 'Email',
    icon: faEnvelope,
  },
];

export default data;
