import * as React from "react";
import { signInWithEmailAndPassword, onAuthStateChanged } from "firebase/auth"
import { auth } from "../firebase-config";


export const UserContext = React.createContext()

export function UserContextProvider(props) {

  const signIn = (email, pwd) => signInWithEmailAndPassword(auth, email, pwd)

  const [currentUser, setCurrentUser] = React.useState();
  const [loadingData, setLoadingData] = React.useState(true);

  React.useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setCurrentUser(currentUser)
      setLoadingData(false)
    })
    return unsubscribe;
  }, [])

  // modal
  const [modalState, setModalState] = React.useState({
    signUpModal: false,
    signInModal: true
  })

  return (
    <UserContext.Provider value={{modalState, currentUser, signIn}}>
      {!loadingData && props.children}
    </UserContext.Provider>
  )
}