import React from 'react';
import { hydrate, render } from 'react-dom';
import App from './App';
import { UserContextProvider } from './layouts/UserContext';

// See https://reactjs.org/docs/strict-mode.html
const StrictApp = () => {
  
  return (
    <React.StrictMode>
      <UserContextProvider>
        <App />
      </UserContextProvider>
    </React.StrictMode>
  );
}

const rootElement = document.getElementById('root');

// hydrate is required by react-snap.
if (rootElement.hasChildNodes()) {
  hydrate(<StrictApp />, rootElement);
} else {
  render(<StrictApp />, rootElement);
}
