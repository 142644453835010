import { HashLink as Link } from 'react-router-hash-link';
import Hamburger from './Hamburger';
import * as React from "react";
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import Zoom from '@mui/material/Zoom';
import { styled } from '@mui/material/styles';
import { auth } from '../../firebase-config';
import { GetCategorieProduct } from '../../firebase-config';

const Navigation = () => {

  const { PUBLIC_URL } = process.env;
  const [cate, setCate] = React.useState(null)

  const routes = [
    {
      index: true,
      label: 'H2L',
      path: '/',
      liste: false,
      logo: true,
      source: `${PUBLIC_URL}/images/projects/logo_menu.png`,
      sousmenu: []
    },
    {
      label: 'Achat',
      path: '/achat',
      liste: true,
      logo: false,
      source: false,
      sousmenu: cate === null ? [] : cate[0].cate
    },
    {
      label: 'Outils coupants',
      path: '/outils',
      liste: true,
      logo: false,
      source: false,
      sousmenu: cate === null ? [] : cate[6].cate
    },
    {
      label: 'Machines',
      path: '/machine',
      liste: true,
      logo: false,
      source: false,
      sousmenu: cate === null ? [] : cate[3].cate
    },
    {
      label: 'Metrologie',
      path: '/metrologie',
      liste: true,
      logo: false,
      source: false,
      sousmenu: cate === null ? [] : cate[4].cate
    },
    {
      label: 'Materiel divers',
      path: '/divers',
      liste: true,
      logo: false,
      source: false,
      sousmenu: cate === null ? [] : cate[2].cate
    },
    {
      label: 'Destockage',
      path: '/destockage',
      liste: false,
      logo: false,
      source: false,
      sousmenu: []
    },
    {
      label: 'Contact',
      path: '/contact',
      liste: false,
      logo: false,
      source: false,
      sousmenu: []
    },
  ];

  const HtmlTooltip = styled(({ className, ...props }) => (
      <Tooltip {...props} classes={{ popper: className }} TransitionComponent={Zoom} />))(({ theme }) => ({
          [`& .${tooltipClasses.tooltip}`]: {
          backgroundColor: 'transparent',
          color: '#3c3b3b',
          width:1000,
          fontSize: theme.typography.pxToRem(6),
          position:'relative',
          top:2
          },
      }));
    
  const adminroute = [
    {
      index: true,
      label: 'H2L',
      path: '/',
      liste: false,
      logo: true,
      source: `${PUBLIC_URL}/images/projects/logo_menu.png`
    },
    {
      label: 'Gestion des stocks',
      path: '/gestion',
      liste: false,
      logo: false,
      source: false
    },
    {
      label: 'Gestion de la base de donnée',
      path: '/ajout',
      liste: false,
      logo: false,
      source: false
    }
  ]

  React.useEffect(() => {
    GetCategorieProduct(setCate)
  }, [])


  if (auth.currentUser === null){
  
    return (
      <header id="header">
        <h1 className="index-link">
          {routes.filter((l) => l.index).map((l) => (
            l.logo ? <Link key={l.label} to={l.path}><img src={l.source} width="50px" alt="" /></Link> : <Link key={l.label} to={l.path}>{l.label}</Link>
          ))}
        </h1>
        <nav className="links">
          <ul>
            {routes.filter((l) => !l.index).map((l) => (
              <li key={l.label}>
                <div>
                  { l.liste ? <HtmlTooltip
                    PopperProps={{
                      disablePortal: true,
                    }}
                    title={
                      <React.Fragment>
                        <div style={{backgroundColor:'rgba(255, 255, 255, 0.5)', backdropFilter: 'blur(10px)', position:'relative'}}>
                        <div className="titlemenu">
                          <p style={{color:'red'}}>
                            Categorie {l.label}
                          </p>
                        </div>
                        {l.sousmenu.map((section) => {
                          return (
                            <div className="menuparams" key={section}>
                              <Link className="menuparams" to={`${l.path}#${section.toLowerCase()}`}>{section}</Link>
                            </div>
                          )
                        })}
                        </div>
                        </React.Fragment>
                    }>
                    <Link to={l.path}>{l.label}</Link>
                  </HtmlTooltip> : <Link to={l.path}>{l.label}</Link>}
                </div>
              </li>
            ))}
          </ul>
        </nav>
        <Hamburger />
      </header>
    )
  }

  else {
    return (
      <header id="header">
        <h1 className="index-link">
        {adminroute.filter((l) => l.index).map((l) => (
            l.logo ? <Link key={l.label} to={l.path}><img src={l.source} width="50px" alt="" /></Link> : <Link key={l.label} to={l.path}>{l.label}</Link>
          ))}
        </h1>
        <nav className="links">
          <ul>
            {adminroute.filter((l) => !l.index).map((l) => (
              <li key={l.label}>
                <div>
                  { l.liste ? <HtmlTooltip
                    PopperProps={{
                      disablePortal: true,
                    }}
                    title={
                      <React.Fragment>
                        <div style={{backgroundColor:'rgba(255, 255, 255, 0.5)', backdropFilter: 'blur(10px)', position:'relative'}}>
                        <div className="titlemenu">
                          <p style={{color:'red'}}>
                            Categorie {l.label}
                          </p>
                        </div>
                        {l.sousmenu.map((section) => {
                          return (
                            <div className="menuparams" key={section}>
                              <Link className="menuparams" to={`${l.path}#${section.toLowerCase()}`}>{section}</Link>
                            </div>
                          )
                        })}
                        </div>
                        </React.Fragment>
                    }>
                    <Link to={l.path}>{l.label}</Link>
                  </HtmlTooltip> : <Link to={l.path}>{l.label}</Link>}
                </div>
              </li>
            ))}
          </ul>
        </nav>
        <Hamburger />
      </header>
    );
  }
}

export default Navigation;
