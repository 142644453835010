import React from 'react';
import { Link } from 'react-router-dom';
import ContactIcons from '../Contact/ContactIcons';
import ReactMarkdown from 'react-markdown';
import Box from '@mui/material/Box';
import raw from 'raw.macro';
import Modal from '@mui/material/Modal';

const { PUBLIC_URL } = process.env; // set automatically from package.json:homepage

const SideBar = () => {

  // mention legales
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const markdown = raw('../../data/contenu/mention.md');

  const LinkRenderer = ({ ...children }) => <Link {...children} />;

    const style = {
      position: 'absolute',
      top: '55%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: "70%",
      height: "85%",
      backgroundColor: "#F0F0F0",
      border: '1px solid gray',
      boxShadow: 24,
      p: 4,
      overflowY:'scroll'
    };

  return (
  <section id="sidebar">
    <section id="intro">
      {/* <Link to="/" className="logoside">
        <img src={`${PUBLIC_URL}/images/projects/perflab.png`} alt="" />
      </Link> */}
      <header>
        <img src={`${PUBLIC_URL}/images/projects/banniere.png`} className='specialimage' alt="" />
        <br />
        <h2 >H2L</h2>
        <h3 >Achat vente matériels industriels</h3>
        <br />
        <p><a href="mailto:js2lh@orange.fr">js2lh@orange.fr</a></p>
        <p><a href="tel:+33671269273">06 71 26 92 73</a></p>
      </header>
    </section>

    <section className="blurb">
      <h2>Qui sommes nous ?</h2>
      <p style={{textAlign:'justify'}}>H2L est une société specialiste dans l'achat vente de materiels industriels. Nous proposons à l'achat et à la vente des machines pour l'usinage, outils, outils coupants & periphériques industriels.
      </p>
      <ul className="action">
          {!window.location.pathname.includes('/contact') ? <Link to="/contact" className='button'>Nous contacter</Link> : <Link to="/contact" className='button'>Nous contacter</Link>}
      </ul>
    </section>

    <section id="footer">
      <ContactIcons />
          <a className="copyright" onClick={() => {handleOpen()}}><Link>Mentions legales</Link></a>&nbsp;&nbsp;&nbsp;&nbsp;
          <a className="copyright"><Link to="/connexion">Centre</Link>.</a>&nbsp;&nbsp;&nbsp;&nbsp;
    </section>
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
        <Box sx={style}>
          <div style={{textAlign:'justify', paddingRight:'4%', paddingLeft:'4%'}}>
            <ReactMarkdown
              renderers={{ Link: LinkRenderer }}
              allowDangerousHtml={false}
            >
              {markdown}
            </ReactMarkdown>
          </div>
        </Box>
    </Modal>
  </section>
)
}

export default SideBar;
